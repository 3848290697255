export const auxiliaryOptions = [
  {
    value: 'OFF',
    text: 'Off',
  },
  {
    value: 'MANUAL',
    text: 'On',
  },
];
