import { useSelector } from 'react-redux';
import { ZoneSettingsResponse } from '../ZoneService.model';
import { getFeatureToggle } from '../../../../../shared/featureToggle';
import { auxiliaryOptions } from '../../../../zoneSettings/serialize/auxiliaryOptions';

/**
 * from zoneSettings from device get a list of options for select
 * @param device
 * @return zoneOptions
 */
export function useGetZoneOptions(
  zoneSettings: ZoneSettingsResponse | undefined,
  zoneIndex: number
): { value: string; text: string }[] {
  const featureToggle = useSelector(getFeatureToggle);

  if (featureToggle?.Zone5Zone6Auxiliary && zoneIndex > 4) {
    return auxiliaryOptions;
  }
  if (zoneSettings) {
    return Object.keys(zoneSettings.configured)
      .filter((key) => zoneSettings.configured[key]?.visible)
      .map((key) => ({
        value: key,
        text: zoneSettings.configured[key]?.displayName || key,
      }));
  }
  return [];
}
