import React, { useMemo } from 'react';
import ZoneSettingsAPI from '../../../../../../../service/zoneSettings/ZoneSettingsAPI';
import { Button, CircularProgress, Stack } from '@mui/material';
import useWatchError from '../../../../../../../shared/hooks/useWatchError';
import { useTranslation } from 'react-i18next';
import InputSelect from '../../../../../../../theme/ui/Atoms/InputSelect/InputSelect';
import getOptionsFromZoneSettingsWithDefaults from '../../../../../../../service/zoneSettings/serialize/getOptionsFromZoneSettingsWithDefaults';
import {
  POSSIBLE_SHELF_ZONE_ID,
  ShelfSettingDayPartResponse,
} from '../../../../../../../service/schedule/ScheduleAPI.model';
import { auxiliaryOptions } from '../../../../../../../service/zoneSettings/serialize/auxiliaryOptions';
import { useSelector } from 'react-redux';
import { getFeatureToggle } from '../../../../../../../shared/featureToggle';

interface ZoneSettingsSmallProps {
  values: ShelfSettingDayPartResponse[];
  onChange: (values: ShelfSettingDayPartResponse[]) => void;
  numberZones?: number;
  regionId?: string;
}
function ZoneSettingsSmall(props: ZoneSettingsSmallProps) {
  const { regionId, values, onChange, numberZones } = props;
  const { t } = useTranslation();
  const {
    isFetching,
    isLoading,
    error,
    data: zoneSettings,
  } = ZoneSettingsAPI.useGetZoneSettingsByStoreQuery(regionId || '', {
    refetchOnMountOrArgChange: true,
  });
  const optionsZoneSettings = useMemo(
    () => getOptionsFromZoneSettingsWithDefaults(zoneSettings),
    [zoneSettings]
  );
  const featureToggle = useSelector(getFeatureToggle);
  const zones = useMemo(() => {
    return new Array(numberZones).fill(null).map((v, i) => ({
      zoneId: `${i + 1}` as POSSIBLE_SHELF_ZONE_ID,
      setting: values.find((v) => v.zoneId === `${i + 1}`)?.setting,
    }));
  }, [numberZones, values]);
  useWatchError(error);

  if (isLoading || isFetching) return <CircularProgress />;
  if (error) return <div>{t('error.unknown')}</div>;
  const handleChange = (index: number, value: string | null) => {
    const newValues = [...values];
    newValues[index] = {
      zoneId: `${index + 1}` as POSSIBLE_SHELF_ZONE_ID,
      setting: value,
    };
    onChange(newValues);
  };

  const getOptions = (index: number): any =>
    featureToggle?.Zone5Zone6Auxiliary && index > 4
      ? auxiliaryOptions
      : optionsZoneSettings;

  return (
    <div>
      <Stack spacing={2}>
        {zones.map((value, index) => (
          <Stack
            spacing={2}
            direction={'row'}
            key={index}
            alignItems={'flex-end'}
          >
            <InputSelect
              select
              id={`zone-settings-${index}`}
              name={`zone-settings-${index}`}
              label={`${t(`timers.zone`)} ${index + 1}`}
              options={getOptions(index + 1)}
              value={value.setting}
              onChange={(
                e: React.ChangeEvent<HTMLInputElement>,
                value: string
              ) => handleChange(index, value)}
              fullWidth
            />
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleChange(index, null)}
              sx={{ height: '3rem' }}
            >
              {t('operator.timer.clear_action')}
            </Button>
          </Stack>
        ))}
      </Stack>
    </div>
  );
}

export default ZoneSettingsSmall;
