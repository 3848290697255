import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useErrorMessage from '../../../../shared/hooks/useErrorMessage';
import useMessage from '../../../../shared/hooks/useMessage';
import { DevicesStoreInfoResponse } from '../../../../service/store/StoreService.model';
import { ZoneResponse } from '../../../../service/store/modules/zone/ZoneService.model';
import useDeviceWithSettingsTimers from '../../../../service/store/hooks/useDeviceWithSettingsTimers';
import {
  getAlertFromZone,
  useGetZoneOptions,
} from '../../../../service/store/modules/zone/util';
import ZoneSelect from '../../../../theme/ui/Atoms/ZoneSelect';
import ZoneApi from '../../../../service/store/modules/zone/ZoneApi';
import produce from 'immer';

interface ZoneConnectedProps {
  item: {
    zone: ZoneResponse | undefined;
    device: DevicesStoreInfoResponse;
  };
  zoneIndex: number;
}
function ZoneConnected(props: ZoneConnectedProps) {
  const {
    item: { zone, device },
    zoneIndex,
  } = props;

  const errorMessage = useErrorMessage();
  const showMessage = useMessage();
  const { t } = useTranslation();

  const [setZoneAPI, { isLoading: setZoneLoading, originalArgs }] =
    ZoneApi.useSetZoneSettingsMutation();

  const onChangeZone = (newSetting: string) => {
    setZoneAPI({
      deviceId: device.uuid ?? '',
      zoneIndex: `${zoneIndex}` as keyof DevicesStoreInfoResponse['zones'],
      newSetting,
    })
      .unwrap()
      .then(() => showMessage(t('timers.zone_set_success')))
      .catch((error) =>
        errorMessage(
          `${t('timers.zone_set_error')} ${t(
            typeof error === 'string' ? error : error.message
          )}`
        )
      );
  };

  const composedDevice = useDeviceWithSettingsTimers(device?.uuid);
  const { timers, zoneSettings } = composedDevice;

  // implement optimist updates
  const zonePatch = useMemo(() => {
    if (!originalArgs || !setZoneLoading || !zone) return zone;
    return produce(zone, (draft) => {
      const { newSetting } = originalArgs;
      const newSettingObj = zoneSettings?.configured[newSetting];
      draft.activeSetting = newSetting;
      if (newSettingObj) {
        draft.shelfStatus.temperatureSetpoint = newSettingObj.setpoint;
        if (newSetting !== 'OFF') draft.shelfStatus.deviceOn = true;
      }
    });
  }, [originalArgs, setZoneLoading, zone, zoneSettings]);
  const options = useGetZoneOptions(zoneSettings, zoneIndex);

  return (
    <ZoneSelect
      zone={zonePatch}
      key={`${device.uuid}-${zoneIndex}`}
      disconnected={!device?.deviceState?.mqttConnected}
      alert={getAlertFromZone(timers, zoneIndex)}
      loading={setZoneLoading}
      options={options}
      onChange={(event: any, newSetting: string) => {
        onChangeZone(newSetting);
      }}
    />
  );
}

export default ZoneConnected;
